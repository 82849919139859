.main-footer {
	background-color: var(--d-grey);

	padding-top: 40px;
	position: relative;

	.footer-cont {
		padding-bottom: 0px;

		.widget-bl {

			margin: 10px 0;

			.footer-form {

				label.control-label {
					display: none;
				}

				.form-control {
					border-radius: 0px;
					border: none;
					background-color: var(--lighter-f);
					font-weight: 300;
					color: var(--white);
					font-size: 11px;
					min-height: 40px;
					padding: .5rem 1rem;
					box-shadow: none;
				}

				.form-group {
					margin-bottom: 11px;

					.form-control::placeholder {
						color: #fff;
						opacity: .6;
					}
				}

				.checkbox {
					label {
						font-weight: 300;
						color: var(--white);
						font-size: 11px;

						a.footer-link {
							color: var(--white);
						}
					}

				}

				.btn {
					padding: 9px 18px;
					font-weight: 600;
					text-transform: uppercase;
					font-size: 14px;
					border-radius: 0px;
					margin-top: 11px;

					&.btn-primary {
						background-color: var(--primary);
						border-color: var(--primary);
						color: var(--white);

						&:hover {
							background-color: var(--white);
							border-color: var(--white);
							color: var(--primary);
						}
					}
				}


			}
		}
	}

	.newsletter-post-bl {
		top: -70px;
		position: absolute;
		z-index: 5;
		width: 100%;
		padding: 0 20px;

		.contact-form {
			margin-bottom: 0;

			p:last-child {
				margin-bottom: 0;
			}

			p,
			.heading1 {
				color: var(--secondary);
			}
		}
	}




	.footer-cont {
		@media (max-width:992px) {
			padding-bottom: 0;
		}
	}

	.newslet-bl {
		background-color: var(--d-blue);
		padding: 15px;
		border-radius: 10px;
		max-width: 1210px;
		margin: 0 auto;
		text-align: center;

		@media (max-width:992px) {
			padding: 16px 20px 10px;
		}

		h4 {
			color: var(--secondary);
			font-size: 16px;
			margin-bottom: 25px;
		}

		h2 {
			color: var(--white);
			text-transform: uppercase;
			margin-bottom: 40px;
			font-size: 21px;

			@media (max-width:992px) {
				margin-bottom: 30px;
			}
		}

		.widt-cont-bl {
			background-color: #01242f;
			color: var(--white);
			border-radius: 30px;
			border: 4px solid #01242f;
			padding-left: 15px;
			display: flex;
			max-width: 420px;
			margin: 0 auto;

			@media (max-width:992px) {
				border: none;
			}

			.form-control {
				border: none;
				background-color: transparent;
				font-size: 16px;
				font-family: var(--d-font);
				height: inherit;
				padding: 10px 20px;
				color: var(--white);

				@media (max-width:992px) {
					padding: 7px 0;
					font-size: 13px;
				}
			}

			.btn-secondary {
				padding: 12px 40px;
				border-radius: 30px;
				color: var(--white);

				@media (max-width:992px) {
					padding: 7px 20px;
					font-size: 14px;
				}
			}
		}
	}

	.social-footer {
		margin-bottom: 0px;

		@media (max-width:992px) {
			text-align: center;
		}

		a {
			display: inline-block;
			margin-right: 10px;
			text-align: center;
			font-size: 24px;
			text-decoration: none;
			color: var(--primary);

			&:hover {
				color: var(--white);
			}

			&:last-child {
				margin-right: 0;
			}

		}
	}

	.ft-logo {
		margin-bottom: 60px;

		img {
			height: 75px;
			/*image-rendering: -webkit-optimize-contrast;*/
		}

		@media (max-width:992px) {
			text-align: center;
		}
	}

	.widget-bl {
		@media (max-width:992px) {
			margin-bottom: 20px;
			border-bottom: 1px solid var(--lighter-f);
			padding-bottom: 25px;
			margin-bottom: 25px;
		}

		.widgt-title {
			font-family: var(--heading);
			color: var(--white);
			margin: 0px 0 20px;
			font-weight: 600;
			display: block;
			font-size: 17px;

			@media (max-width:992px) {
				margin-bottom: 30px;
			}
		}
	}

	.blue-li {
		li {
			list-style: none;
			margin-bottom: 0px;
			padding-bottom: 5px;

			a {
				color: var(--lighter-l);
				font-weight: 300;

				&:hover {
					/*color: var(--primary);*/
				}
			}

			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}
	}

	.icon-white-li {


		@media (min-width:993px) {
			display: flex;
			flex-wrap: wrap;
		}

		li {
			list-style: none;
			margin-bottom: 5px;
			position: relative;
			padding-left: 40px;
			color: var(--white);

			&:last-child {
				margin-bottom: 0;
			}

			i {
				font-size: 18px;
				position: absolute;
				left: 0;
				top: 0px;
				color: var(--primary);

				&.fa-phone-alt {
					/*top: 5px;*/
				}
			}

			a {
				color: var(--lighter);
				font-size: 13px;
				font-weight: 400;
				margin: 0;

				&:hover {
					color: var(--lighter);
				}
			}

			h4 {
				margin: 0;
			}

			span {
				/*font-size: 14px;
				opacity: .5;*/
				display: block;

			}

			.address {
				color: var(--lighter);
				font-size: 13px;
			}
		}
	}

	.copyright {
		padding-bottom: 0px;
		background-color: var(--l-grey);

		p {
			font-size: 13px;
			font-weight: 300;
			color: var(--lighter-x);
			line-height: 32px;

		}

		@media (max-width:992px) {
			text-align: center;
		}
	}
}