@import "setup.css";

.header-style2 {
	.mainHeader {
		/*padding: 14px 0 !important;
		border-bottom: 3px solid #f9f9f9;*/
		background-color: rgba(255, 255, 255, 1) !important;
		padding: 0px !important;

		.navbar-area {

			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;
							color: var(--primary);
							font-family: var(--font);
						}
					}
				}

				.navbar-nav>li.dropdown i.far {
					display: none;
				}
			}


		}
	}
}

.lang {
	background-repeat: no-repeat !important;
	background-position: left center !important;
	width: 18px;
	height: 12px;
	display: inline-block;
	vertical-align: middle;

	&.langen {
		background: url(../img/en_GB.png);
	}

	&.langde {
		background: url(../img/de_DE.png);
	}

	&.langnl {
		background: url(../img/nl_NL.png);
	}

	@media (max-width: 992px) {
		background-position: center top !important;
	}

}




.navbar-area {

	>.srch-btn {
		color: var(--primary) !important;



		i.fal,
		i.far {
			@media (max-width:992px) {
				display: none;
			}
		}
	}
}

.nav-right {
	.srch-btn {
		padding: 2px 8px;
	}

	.nav-btn {
		background-color: var(--black);
		color: var(--white);
		text-align: center;
		font-size: 10.9px;
		font-weight: 600;
		text-transform: uppercase;
		line-height: 1.2em;
		letter-spacing: 1px;
		border-style: solid;
		border-width: 1px 1px 1px 1px;
		border-color: var(--black);
		border-radius: 5rem;
		padding: 12px 30px;
		width: 112px;

		&:hover {
			background-color: var(--primary);
			color: var(--white);
			border-color: var(--primary);
		}

		>span {
			width: 50px;
			display: inline-block;
			padding-right: 5px;

			@media (max-width:992px) {
				width: auto;
			}
		}

		@media (max-width:992px) {
			width: auto;
		}

		@media (max-width:767px) {
			display: none;
		}
	}
}

.vo-nav-bar {

	.top {
		background-color: var(--d-black);
		padding: 10px 0;

		ul {
			margin: 0px;
			padding: 0px;
			text-align: right;

			li {
				list-style-type: none;
				display: inline-block;
				margin-left: 16px;


				a {
					text-decoration: none;
					color: var(--white);
					font-size: 15px;
				}
			}

			@media (max-width:767px) {
				text-align: center;

				li {
					margin: 0 8px;
				}
			}
		}


	}

	&.mainHeader {
		position: fixed;
		width: 100%;
		top: 0px;
		z-index: 50;
		/*display: flex;*/
		align-items: center;
		padding: 28px 0;
		transition: all ease-in .2s;
		background-color: rgba(255, 255, 255, 0);

		@media (max-width:992px) {
			/*padding: 14px 0 !important;
			border-bottom: 3px solid #f9f9f9;*/
			background-color: #fff !important;
			padding: 0 !important;
		}

		.navbar-area {
			display: flex;
			justify-content: space-between;
			align-items: center;
			/*padding: 0 25px;*/
			padding: 10px 0;

			@media (max-width:992px) {
				/*padding: 0;*/
				padding-left: 60px;
				position: relative;
			}

			.srch-btn {
				font-size: 20px;
				color: var(--white);
				background: var(--primary);
				border-color: var(--primary);


				&:hover {
					background: var(--black);
					border-color: var(--black);
				}

				@media (max-width:992px) {
					margin-right: 5px;
					color: var(--primary);
				}
			}

			.logo img {
				height: 41px;
				/*image-rendering: -webkit-optimize-contrast;*/


			}

			.logo {
				@media (max-width:767px) {
					position: absolute;
					left: 0;
					right: 0;
					text-align: center;
				}
			}
		}

		.logo {
			a {
				width: 165px;

				@media (max-width:1024px) {
					max-width: 140px;
				}
			}


		}

		.nav-right {
			display: flex;
			justify-content: flex-end;

			.fav-count {
				color: var(--primary);
				position: relative;
				/*margin-left: 5px;*/
				margin-left: 20px;
				/*top: 6px;*/
				top: 15px;

				@media (max-width:992px) {
					top: 2px;
				}


				.fa {
					font-size: 24px;
				}

				.sub-count {
					display: block;
					position: absolute;
					top: -6px;
					left: 7px;
					width: 18px;
					height: 18px;
					background: red;
					background: var(--secondary);
					color: var(--white);
					text-align: center;
					font-size: 12px;
					line-height: 18px;
					border-radius: 50%;
				}
			}
		}

		.topNav {
			.navbar {
				min-height: inherit;
				margin: 0;

				@media (max-width:992px) {
					position: inherit;
				}
			}

			.navbar-toggler {
				background: transparent;
				font-size: 24px;
				color: var(--secondary);
				padding: 0;
				border: none;
				box-shadow: none;
				display: none;

				@media (max-width:992px) {
					display: inline-block;
					position: absolute;
					left: 15px;
					top: 8px;

				}
			}



			ul {
				margin: 0;
				padding: 0px;

				&.two-col-drop {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					width: 440px;

					li {
						@media (min-width:993px) {
							width: 50% !important;
						}
					}
				}

				li {
					list-style: none;
					padding: 0px;
					margin: 0px;

					@media (max-width:1240px) {
						margin: 0 2px;
					}

					a {
						font-size: 12px;
						color: var(--white);
						display: inline-block;
						padding: 12px 18px;
						background-color: transparent;
						text-align: left;
						text-transform: uppercase;
						font-weight: 500;
						text-shadow: none;
						border-radius: 0px;

						i {
							margin-left: 5px;
						}

						@media (max-width:1240px) {
							padding: 5px;
							/*font-size: 14px;*/
						}

						@media (max-width:992px) {
							/*padding: 7px 0;*/

						}

						span {
							padding: 3px 5px;
							border-radius: 3px;
							background-color: var(--secondary);
							color: var(--white);
							display: inline-block;
							line-height: 13px;
							font-size: 14px;
							margin-left: 3px;
						}
					}

					&:hover {
						a {
							/*background-color: var(--primary);*/

						}

						.dropdown-menu {
							display: block;
						}
					}

					.dropdown-menu {
						/*min-width: 260px;*/
						left: 0px;
						margin: 0px;
						padding: 0;
						background-color: var(--secondary);
						border: none;
						border-radius: 0;
						margin-top: -1px;
						box-shadow: 0 0 18px 0 rgba(0, 0, 0, .04);

						@media (max-width:992px) {
							ul {
								padding: 0;
							}
						}

						li {
							float: left;
							width: 100%;
							border-bottom: 1px solid var(--border-dark);
							margin: 0px;


							&:last-child {
								border-bottom: none;
							}

							&:hover {
								background-color: var(--primary);
								color: var(--white);
							}



						}

						a {
							font-size: 12px;
							display: block;
							padding: 0;
							color: var(--white);
							padding: 11px 18px;
							line-height: 20px;
							border-left: 8px solid transparent;
							font-family: var(--font);
							white-space: nowrap;

							&:hover {
								/*color: var(--secondary);*/
							}

							&.lang {
								min-height: 42px;
								width: 100%;
								border-left-width: 26px;
							}


						}
					}
				}

			}
		}
	}

	&.affix {
		/*padding: 14px 0 !important;
		border-bottom: 3px solid #f9f9f9;*/
		background-color: rgba(255, 255, 255, 1);

		.navbar-area {

			.topNav {
				ul {
					li {
						a {
							text-decoration: inherit;
							text-shadow: none;
							/*color: var(--black);*/
						}
					}
				}
			}


		}
	}

	@media (max-width:992px) and (min-width: 768px) {
		.navbar-collapse.collapse {
			display: none !important
		}

		.navbar-collapse.collapse.in,
		.navbar-collapse.collapsing {
			display: block !important
		}
	}

	@media screen and (max-width: 992px) {

		.topNav {

			.navbar-collapse {
				position: absolute;
				left: 0px;
				right: 0px;
				top: 58px;
				background: var(--primary);
				padding: 0px;
				overflow: scroll !important;
				max-height: 450px;


				ul {
					magin: 0px;
					float: left;
					width: 100%;
					padding: 10px 15px;

					li {
						float: left;
						width: 100%;

						margin: 0px;

						a {
							color: #ffffff;
						}

						&:hover {
							a {
								color: #ffffff;
							}

							.dropdown-menu {
								display: none;
							}
						}

						&.open {
							.dropdown-menu {
								display: block;
								position: static;
							}
						}

						.dropdown-menu {
							position: static;
							float: none;
							width: auto;
							margin-top: 0;
							background-color: transparent;
							border: 0;
							box-shadow: none;

						}
					}
				}
			}
		}
	}
}

.mm-page {
	&.mm-slideout {
		z-index: inherit;
	}
}

@media (max-width: 992px) {

	.mobile-navigation {
		ul.mm-listview {
			li {

				&.last {
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;

					a {
						border: 1px solid var(--orangeColor);
						border-radius: 100px;
						text-transform: uppercase;
						padding: 10px 30px;
						max-width: 125px;
						font-size: 13px;
						margin: 22px 0 0 0;

						&:hover {
							background: var(--orangeColor);
							border-color: var(--orangeColor);
						}
					}
				}
			}
		}

	}

	.mm-menu_offcanvas {
		width: 100vw;
		min-width: 100vw;
		z-index: 51;
		max-width: 100vw;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout {
		-webkit-transform: translate3d(100vw, 0, 0);
		transform: translate3d(100vw, 0, 0);
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.navbar-nav,
	.navbar-nav>li {
		float: none !Important;
		padding: 0 15px;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}

	.mm-menu_offcanvas {
		display: none;
	}

	.mm-wrapper_opening .mm-menu_offcanvas {
		display: block;
	}

	.mm-wrapper_opening .mm-page.mm-slideout {
		display: none;

	}

	.mm-wrapper_opened .main-footer {
		display: none;
	}

	.mm-navbar {
		background: none;
		border-bottom: 1px solid rgba(255, 255, 255, .5);
		padding-top: 50px;
		margin-bottom: 20px;
		padding-bottom: 10px;
		z-index: 2;
	}

	.mm-navbar__title>span {
		color: var(--white);
		font-size: 20px;
		font-weight: bold;
	}

	.dropdown {
		i {
			display: none;
		}
	}



	.mm-panel {
		background-image: var(--mobileMenuImg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		margin: 0;
		position: relative;

		&:after {
			position: absolute;
			/*z-index: 1;*/
			width: 100%;
			height: 100%;
			left: 0px;
			top: 0px;
			content: "";
			background-color: rgba(187, 153, 124, 0.85);

		}
	}

	.mm-listitem {
		text-align: center;
		font-size: 20px;
		color: var(--white);
		z-index: 2;

		a {
			span {
				padding: 0 8px 2px;
				line-height: 1;
				font-size: 14px;
				border-radius: 3px;
				background-color: var(--primary);
				position: relative;
				top: -2px;
			}
		}


	}

	.mm-listitem:after {
		display: none;
	}

	.mm-listitem__btn {
		border: none !important;
		position: absolute;
		right: 0;
		top: 12px;
		color: var(--white);
	}

	.mm-btn_next {
		position: absolute;
		left: 0;
		width: 100%;
		height: 30px;
		top: 6px;

		&:after {
			right: 30px;
		}
	}


	.mm-btn:after,
	.mm-btn:before {
		border-color: var(--white);
	}

	.mm-listview {
		max-height: 75vh;
		padding-bottom: 35px;
	}


	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-wrapper__blocker.mm-slideout {
		-webkit-transform: translate3d(87vw, 0, 0);
		transform: translate3d(87vw, 0, 0);
		height: 50px;
		z-index: 51;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly {
		height: 50px !important;
		width: 50px !important;
		clip-path: inherit !important;
		-webkit-clip-path: inherit !important;
		clip: inherit !important;
		font-size: 0;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened~.mm-slideout .mm-sronly:before {
		content: "\f410";
		font-family: 'Font Awesome 5 Free';
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		color: #fff;
		padding: 7px;
		float: left;
	}
}