.payment-view {
	min-height: 500px;
	padding-top: 140px;

	@media (max-width:992px) {

		h1 {
			font-size: 28px;
		}
	}
}